import { get, patch, post } from "../utils/http.util";

const BASE_URL = process.env.REACT_APP_API_URL ?? 'https://dev-api.degencoinflip.com/v2';

export const processPreorder = async (
  id: any,
  signature: any = null,
  Authorization: any = null
) => {
  const url = `${BASE_URL}/preorders/${id}`
  const response = await post(url, { signature }, { Authorization });
  return response?.payload;
};

export const initPreorder = async (amount: any, Authorization: any) => {
  const url = `${BASE_URL}/preorders`
  const response = await post(url, { amount }, { Authorization });
  return response?.data?.payload;
};


export const getPreorders = async (walletId: any) => {
  const url = `${BASE_URL}/preorders?walletId=${walletId}`
  const response = await get(url);
  return response?.payload;
};


export const getMyWhitelists = async (Authorization = '') => {
  const url = `${BASE_URL}/whitelists/me`
  const response = await get(url, { Authorization });
  return response?.payload;
};
