import { Idl, Program, BN, web3 } from '@project-serum/anchor';
import { LAMPORTS_PER_SOL, TransactionInstruction } from '@solana/web3.js';
import moize from 'moize';

import { getSolanaProvider } from "../services/solana";
import idlDev from '../interfaces/pre-order-dev.idl.json';
import idl from '../interfaces/pre-order.idl.json';
import { MEMO_ID, PREORDER_ID, PREORDER_WALLET_DEV, PREORDER_WALLET_ID } from '../utils/program-constants';
import { MINT_PRICE } from '../utils/constants';

const constants = {
    programIdl: process.env.REACT_APP_RPC_URL?.includes("dev") ? idlDev : idl,
    PREORDER_WALLET_ID: process.env.REACT_APP_RPC_URL?.includes("dev") ? PREORDER_WALLET_DEV : PREORDER_WALLET_ID,
    FUNCTION: process.env.REACT_APP_RPC_URL?.includes("dev") ? 'give' : 'preorder'
}

const {
    SystemProgram,
    PublicKey,
} = web3;

let programID: any;
let program: any;
let provider: any;


const init = moize((wallet: any = null) => {
    if (program) return;
    programID = new PublicKey(constants?.programIdl.metadata.address);
    provider = getSolanaProvider(wallet);
    program = new Program(constants?.programIdl as Idl, programID, provider);
});

export const preOrderCat = async (wallet: any, id: any, amount: number = 1) => {
    init(wallet);
    return await program.rpc[constants.FUNCTION](
        new BN(MINT_PRICE * LAMPORTS_PER_SOL * amount),
        {
            accounts: {
                payer: provider.wallet.publicKey,
                fund: constants.PREORDER_WALLET_ID,
                systemProgram: SystemProgram.programId
            },
            instructions: [
                new TransactionInstruction({
                    keys: [{ pubkey: provider.wallet.publicKey, isSigner: true, isWritable: true }],
                    data: Buffer.from(`id=${id}`, "utf-8"),
                    programId: MEMO_ID
                })
            ]
        }
    );;
};
