import { useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";

import { StyleThemeContext } from "../../../contexts/style-theme.context";
import { CommunityContext } from "../../../contexts/community.context";
import { RecentCoinFlipsProvider } from "../../../contexts/recent-coin-flips.context";
import { SocialBar, ToolBar } from "../../components";
import MenuBar from "../../components/menu-bar";
import { TopCoinFlipsProvider } from "../../../contexts/top-coin-flips.context";
import InfoBar from "../../components/info-bar";
import { SiteStatsProvider } from "../../../contexts/stats.context";

const EMPTY_PAGES = [
    '/'
];

const ClassicLayout = () => {
    const { style } = useContext(StyleThemeContext);
    const { community } = useContext(CommunityContext);
    const location = useLocation();

    const bgImageUrl = isMobile ? community?.backgroundImageMobile ?? community?.backgroundImage : community?.backgroundImage;
    return (
        <div className={style}>
            <div className={"classic-container h-sm-100" + ((EMPTY_PAGES.includes(location?.pathname) && EMPTY_PAGES.includes(location?.pathname) && bgImageUrl?.length) ? ' bg-image h-100vh' : '')}
                style={{
                    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${EMPTY_PAGES.includes(location?.pathname) ? bgImageUrl : ''})`
                    // backgroundImage: `url(${EMPTY_PAGES.includes(location?.pathname) ? bgImageUrl : ''})`
                }}>
                <SiteStatsProvider>
                    <RecentCoinFlipsProvider>
                        <TopCoinFlipsProvider>
                            {/* <ToolBar></ToolBar> */}
                            {/* <MenuBar></MenuBar> */}
                            <Outlet></Outlet>
                            {/* <InfoBar></InfoBar> */}
                        </TopCoinFlipsProvider>
                    </RecentCoinFlipsProvider>
                </SiteStatsProvider>
            </div>
        </div>
    );
};
export default ClassicLayout;