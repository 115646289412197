import { useWalletNfts } from "@nfteyez/sol-rayz-react";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { clusterApiUrl, Connection } from "@solana/web3.js";
import { useContext, useEffect, useState } from "react";
import { compile } from "sass";
import { getBurnedTokens, initOgBurn, processOgBurn } from "../../api/burn-nft.service";
import { getMyWhitelists, initPreorder, processPreorder } from "../../api/preorders.service";
import { deleteWhitelist, getWhitelistDetails } from "../../api/whitelist.service";
import { LoadingBars } from "../../components/LoadingBars";
import { InfoModal } from "../../components/modals/InfoModal";
import { NftCard } from "../../components/NftCard";
import { ToastCongrats } from "../../components/toasts/CongratsToast";
import { AudioContext } from "../../contexts/audio.context";
import { AuthorizationContext } from "../../contexts/auth.context";
import { StyleThemeContext } from "../../contexts/style-theme.context";
import { burnNFT, burnNFTs, burnTx, createBurnTx } from "../../services/burn.service";
import { preOrderCat } from "../../services/preorder.service";
import { getSolanaProvider } from "../../services/solana";
import { MINT_PRICE } from "../../utils/constants";
import './poverty.scss'

const URL = process.env.REACT_APP_RPC_URL ?? clusterApiUrl("devnet");
const SILLHOUTE = "https://i.imgur.com/1gMf6zk.png";
const SILLHOUTE_2 = "https://i.imgur.com/YWdR97x.png";
const SILLHOUTE_3 = "https://i.imgur.com/A7FltAi.png";
const BUTTON_SILVER = "https://i.imgur.com/go7GZ22.png";
const BUTTON_GOLD = "https://i.imgur.com/C6VhUqi.png";
const cats = [SILLHOUTE, SILLHOUTE_2, SILLHOUTE_3];

const MINT_INFO_LINK = "https://twitter.com/degenfatcats/status/1541876877912162306";

export const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

const PreOrderContainer = ({ state, onPreorder, onOpenModal, onDonate, loading, buttonLoading }: any) => {
    const [amount, setAmount] = useState(1);
    const handleChangePrice = (e: any) => {
        e.preventDefault(); // prevent the default action
        setAmount(e.target.value); // set name to e.target.value (event)
    }

    return (
        <>
            <section className="position-relative pt-5">
                <div className="text-center mt-0 mt-sm-5 my-3">
                    <h1 className="mt-0 mt-sm-5 mb-1 pt-5 text-light fancy-text">
                        Degen Fat Cats Poverty Giveaway
                    </h1>
                </div>
                <div className="d-flex justify-content-center mb-3">
                    <h5 className="text-center mt-3 px-2 px-sm-0" style={{ maxWidth: '600px' }}>
                        Don't have enough sol to mint and have a key? We got your back! Donate your WL Key and enter the poverty raffle.
                        1 winner wins 1 SOL, 1 winner wins 2 SOL, and 1 winner wins a Degen Fat Cat NFT.
                    </h5>
                </div>
                <div className="d-flex justify-content-center mb-3">
                    <div className="text-center" style={{ maxWidth: '600px' }}>
                        <img src={`https://i.imgur.com/AuH2zr7.gif`} className="floating shadow-none" alt="..." />

                        {
                            !!state?.valid &&
                            <h4 className="title fancy-text mb-2">
                                Are you sure you want to donate your 1 WL Key? You will no longer be able to Mint DFC.
                            </h4>
                        }
                        {
                            !state?.valid && !state?.donated &&
                            <h4 className="title fancy-text mb-2">
                                You have no wl keys!
                            </h4>
                        }
                        {
                            !state?.valid && state?.donated &&
                            <h4 className="title fancy-text mb-2">
                                You have entered the poverty giveaway.
                            </h4>
                        }
                    </div>
                </div>
                <div className="d-flex justify-content-center mb-4">
                    <>
                        <div className="d-flex justify-content-center">
                            {
                                !buttonLoading &&
                                <>
                                    {/* <img onClick={() => onPreorder(+amount)} className="cursor-pointer double-button my-3 img-fluid" src={BUTTON_GOLD} alt="double or nothing" width="240px" height="100%" /> */}



                                    <button type="button" disabled={!state?.valid} className="btn btn-danger btn-lg px-3 px-sm-4" onClick={() => onDonate()}>
                                        {
                                            !!state?.valid &&
                                            <span className="">
                                                FORFEIT MY KEY, I CAN'T AFFORD 10 SOL
                                            </span>
                                        }
                                        {
                                            !state?.valid &&
                                            <span className="">
                                                {
                                                    !state?.donated &&
                                                    <>
                                                        NO WL KEY FOUND
                                                    </>
                                                }
                                                {
                                                    state?.donated &&
                                                    <>
                                                        WL KEY ALREADY DONATED
                                                    </>
                                                }
                                            </span>
                                        }
                                    </button>
                                </>
                            }
                            {
                                buttonLoading &&
                                <div className="my-5">
                                    <LoadingBars />
                                </div>
                            }
                        </div>
                    </>
                </div>
            </section>
            {
                loading &&
                <div className="d-flex h-100vh">
                    <div className="mx-auto my-auto">
                        <LoadingBars />
                    </div>
                </div>
            }
        </>

    )
}

export const PovertyPage = () => {
    const wallet = useWallet();
    const { style } = useContext(StyleThemeContext);
    const { auth, signIn, signInViaLedger } = useContext(AuthorizationContext);
    const { play } = useContext(AudioContext);

    const [showInfoModal, setShowInfoModal] = useState(false);
    const handleInfoModalOpen = () => setShowInfoModal(true);
    const handleInfoModalClose = () => setShowInfoModal(false);

    const [loading, setLoading] = useState<any>(false);
    const enableLoading = () => setLoading(true);
    const disableLoading = () => setLoading(false);


    const [buttonLoading, setButtonLoading] = useState<any>(false);
    const enableButtonLoading = () => setButtonLoading(true);
    const disableButtonLoading = () => setButtonLoading(false);

    const [state, setState] = useState<any>();

    const [congratsToastShow, setCongratsToastShow] = useState<boolean>(false);

    useEffect(() => {
        if (wallet.connected)
            fetchWhitelistDetails(wallet?.publicKey?.toString());
    }, [wallet])

    // useEffect(() => {
    //     if (auth?.idToken)
    //         fetchWhitelistDetails(auth?.idToken);
    // }, [auth])

    // const authorize = async (walletId: any) => {
    //     enableLoading();
    //     try {
    //         await signIn(walletId as string, `` as string);
    //     }
    //     catch {
    //         disableLoading();
    //         return;
    //     }
    //     disableLoading();
    // }
    const fetchWhitelistDetails = async (walletId: any) => {
        enableLoading();
        try {
            const details = await getWhitelistDetails(walletId);
            setState(details);
        } catch {
            setState(null);
            disableLoading();
        }
        disableLoading();
        // const response = await getMyWhitelists(token);
        // setState(response);
    }

    const preorder = async (wallet: any, amount: number = 1) => {
        enableButtonLoading();
        try {
            const { id } = await initPreorder(amount, auth?.idToken);
            const tx = await preOrderCat(wallet, id, amount);
            const response = await processPreorder(id, tx, auth?.idToken);
            setCongratsToastShow(true);
        }
        catch {
            disableButtonLoading();
            return;
        }

        disableButtonLoading();
    }

    const onCloseInfoModal = async () => {
        enableLoading();
        try {
            const auth: any = await signIn(
                wallet?.publicKey?.toString() as string,
                `` as string
            );
            await fetchWhitelistDetails(auth?.idToken);
        }
        catch {
            disableLoading();
            return;
        }

        disableLoading();
        handleInfoModalClose();
    }

    const onLoginViaLedger = async () => {
        enableLoading();
        try {
            const auth: any = await signInViaLedger(wallet);
            await fetchWhitelistDetails(auth?.idToken);
        }
        catch {
            disableLoading();
            return;
        }

        disableLoading();
        handleInfoModalClose();
    }

    const onDonate = async (walletId: any) => {
        enableButtonLoading();
        try {
            const auth: any = await signIn(walletId as string, `` as string);
            const response = await deleteWhitelist(auth?.idToken);
            setCongratsToastShow(true);
            setState({ donated: true });
        }
        catch {
            disableButtonLoading();
            return;
        }
        disableButtonLoading();
    }

    return (
        <>
            <ToastCongrats
                show={congratsToastShow}
                onClose={() => setCongratsToastShow(false)}>
            </ToastCongrats>
            {
                showInfoModal &&
                <InfoModal
                    wallet={wallet}
                    show={showInfoModal}
                    isAuth={!!auth}
                    loading={loading}
                    styleCss={`dark`}
                    onLoginViaLedger={onLoginViaLedger}
                    onClose={() => onCloseInfoModal()}
                />
            }
            {
                !wallet.connected &&
                <section className="h-100vh d-flex">
                    <WalletMultiButton style={{ margin: 'auto' }} />
                </section>
            }
            {
                wallet.connected && !showInfoModal &&
                <>
                    <PreOrderContainer
                        onPreorder={(amount: any) => preorder(wallet, amount)}
                        state={state}
                        onOpenModal={handleInfoModalOpen}
                        loading={loading}
                        onDonate={() => onDonate(wallet?.publicKey?.toString())}
                        buttonLoading={buttonLoading}
                    />
                </>
            }
        </>
    )
}