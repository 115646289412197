

import { del, get } from "../utils/http.util";

// const BASE_URL = 'http://localhost:3000';
const BASE_URL = process.env.REACT_APP_API_URL ?? 'https://dev-api.degencoinflip.com/v2';

export const getWhitelistDetails = async (walletId: any) => {
    const url = `${BASE_URL}/communities/dfc/whitelist?walletId=${walletId}`
    const response = await get(url);
    return response?.payload;
}

export const deleteWhitelist = async (Authorization: any = '') => {
    const url = `${BASE_URL}/communities/dfc/whitelist`
    const response = await del(url, { Authorization });
    return response?.payload;

}